import * as React from "react";
import Layout from "../components/Layout";
import ScaleIcon from "../components/icons/scale-icon";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <section className="section is-medium has-text-centered">
      <div className="container">
        <div className="content">
          <div
            className="is-justify-content-center"
            style={{
              height: "230px",
              display: "flex",
            }}
          >
            <ScaleIcon />
          </div>
          <h1 className="is-size-1">Page Not Found</h1>
          <p className="is-size-5">
            It seems as though the page you're looking for doesn't exist.
            <br />
            Please reach out to use if you have any questions or concerns.
          </p>
          <Link to="/">Return to the Home Page</Link>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
